/* eslint-disable */
// Simulate Auth0 authentication
class FakeAuth {
  constructor() {
    this.isAuthenticated = true
    this.user = {
      name: 'techiitc@plasmasec.com',
    }
    this.isLoading = false
    this.dev = true
  }

  logout() {
    // this logout function is set to avoid error while import fake auth in development mode at AppHeader.js line 88
    alert('log out is not available, check the console')
    throw new Error(
      'IITC Custom Error: log out is not available in development mode: refer to src/fakeAuth.js',
    )
  }
}

const fakeAuth = () => {
  // return new FakeAuth() //! Comment this line to enable Auth0 in Production
  return 0
}
// console.log(fakeAuth)
export default fakeAuth
