// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#settingsTextWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* Adjusted gap for better spacing */
  padding: 2% 5%;
  box-sizing: border-box;
}

#settingsTextContainer {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.settingsText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: min-content;
}

.settingsBtnWrapper {
  display: flex;
  gap: 20px;
  padding: 2% 5%;
  width: 100%;
  justify-content: flex-start;
}

.settingsBtn {
  border: 1px solid black;
  border-radius: 0;
  width: 100px;
  font-weight: bold;
}

.settingsBtnGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
}

.settingsBtnContainer {
  display: flex;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/views/pages/Settings/settings.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,eAAe;EACf,SAAS;EACT,oCAAoC;EACpC,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,cAAc;EACd,WAAW;EACX,2BAA2B;AAC7B;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":["#settingsTextWrapper {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  gap: 10px;\n  /* Adjusted gap for better spacing */\n  padding: 2% 5%;\n  box-sizing: border-box;\n}\n\n#settingsTextContainer {\n  width: 45%;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.settingsText {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  height: min-content;\n}\n\n.settingsBtnWrapper {\n  display: flex;\n  gap: 20px;\n  padding: 2% 5%;\n  width: 100%;\n  justify-content: flex-start;\n}\n\n.settingsBtn {\n  border: 1px solid black;\n  border-radius: 0;\n  width: 100px;\n  font-weight: bold;\n}\n\n.settingsBtnGroup {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 40%;\n}\n\n.settingsBtnContainer {\n  display: flex;\n  gap: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
