import React, { useEffect, useState, createContext, useContext } from 'react'
import {
  CContainer,
  CCardHeader,
  CCard,
  CDropdown,
  CDropdownToggle,
  CDropdownItem,
  CDropdownMenu,
} from '@coreui/react'
import './settings.css'
import fakeAuth from 'src/fakeAuth'
import { useAuth0 } from '@auth0/auth0-react'

// Create a context to manage the timezone
const TimezoneContext = createContext()

// Custom hook to use the timezone context
export function useTimezoneContext() {
  return useContext(TimezoneContext)
}

export function TimezoneProvider({ children }) {
  const [timezone, setTimezone] = useState('')

  useEffect(() => {
    const savedTimezone = localStorage.getItem('timezone')
    if (savedTimezone) {
      setTimezone(savedTimezone)
    } else {
      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      setTimezone(browserTimezone)
    }
  }, [])

  const handleSelectTimezone = (selectedTimezone) => {
    setTimezone(selectedTimezone)
  }

  const saveTimezone = (selectedTimezone) => {
    localStorage.setItem('timezone', selectedTimezone)
  }

  return (
    <TimezoneContext.Provider value={{ timezone, handleSelectTimezone, saveTimezone }}>
      {children}
    </TimezoneContext.Provider>
  )
}

function Settings() {
  const [orgID, setOrgID] = useState('')
  const [orgName, setOrgName] = useState('')
  const [selectedTimezone, setSelectedTimezone] = useState('')
  const [previousTimezone, setPreviousTimezone] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const auth0 = useAuth0()
  const { user, isLoading, isAuthenticated } = auth0 || fakeAuth()
  const { timezone, handleSelectTimezone, saveTimezone } = useTimezoneContext()

  useEffect(() => {
    if (timezone) {
      setSelectedTimezone(timezone)
      setPreviousTimezone(timezone) // Set previous timezone on load
    }
  }, [timezone])

  useEffect(() => {
    if (user) {
      getOrganizationName()
    }
  }, [user])

  const getOrganizationName = () => {
    try {
      const email = user.name
      const orgName = email.split('@')[1].substring(0, email.split('@')[1].lastIndexOf('.com'))
      const orgWithCapital = orgName.charAt(0).toUpperCase() + orgName.slice(1)
      setOrgName(orgWithCapital)
      return orgWithCapital
    } catch (error) {
      return ''
    }
  }

  const saveSettings = () => {
    saveTimezone(selectedTimezone)
    setPreviousTimezone(selectedTimezone) // Update previous timezone on save
    setShowSuccessMessage(true)
    setTimeout(() => {
      setShowSuccessMessage(false)
    }, 3000)
    console.log('Settings saved!')
  }

  const cancelSettings = () => {
    setSelectedTimezone(previousTimezone) // Revert to previous timezone on cancel
  }

  let username = ''
  if (!isLoading && isAuthenticated && user) {
    const userNameWithCapital = user.name.charAt(0).toUpperCase() + user.name.slice(1)
    username = userNameWithCapital.split('@')[0]
  }

  return (
    <CContainer style={{ minWidth: '82.5vw' }}>
      <CCard style={{ height: '70vh' }}>
        <CCardHeader className="card-header-text text-center">Settings</CCardHeader>
        <section id="settingsTextWrapper">
          <div id="settingsTextContainer">
            <p className="settingsText">
              Username: <span className="settingsDataDisplay">{username}</span>
            </p>
            <p className="settingsText">
              Organization: <span className="settingsDataDisplay">{orgName}</span>
            </p>
            <p className="settingsText">
              Organization ID: <span className="settingsDataDisplay">placeholder</span>
            </p>
            <p className="settingsText">
              Organization Unified Timezone:{' '}
              <CDropdown className="settingsBtnGroup" variant="btn-group">
                <CDropdownToggle color="secondary" size="sm">
                  {selectedTimezone || 'Select a value'}
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem onClick={() => setSelectedTimezone('GMT')}>GMT</CDropdownItem>
                  <CDropdownItem onClick={() => setSelectedTimezone('(UTC+2) Jerusalem')}>
                    (UTC+2) Jerusalem
                  </CDropdownItem>
                  {/* Add more timezones as needed */}
                </CDropdownMenu>
              </CDropdown>{' '}
            </p>
            <strong style={{ paddingTop: '5%' }} className="settingsText">
              Current timezone is: <span className="settingsDataDisplay">{timezone}</span>
            </strong>
            {showSuccessMessage && (
              <div className="success-message">Settings saved successfully!</div>
            )}
          </div>
        </section>
        <section className="settingsBtnWrapper">
          <button type="button" color="light" className="settingsBtn" onClick={saveSettings}>
            Save
          </button>
          <button type="button" color="light" className="settingsBtn" onClick={cancelSettings}>
            Cancel
          </button>
        </section>
      </CCard>
    </CContainer>
  )
}

export default Settings
