import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
// import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { createRoot } from 'react-dom/client'
import Auth0ProviderWithHistory from './auth0Provider'

// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById('root'),
// )

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <Auth0ProviderWithHistory>
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0ProviderWithHistory>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
