import React, { Component, Suspense, useDeferredValue, useEffect, useState } from 'react'
import { BrowserRouter, Outlet, Route, Routes, Navigate } from 'react-router-dom'
// import Auth0ProviderWithHistory from './auth0Provider'
import './scss/style.scss'
import fakeAuth from './fakeAuth' //! For testing purposes
import { useAuth0 } from '@auth0/auth0-react'
import { TimezoneProvider } from './views/pages/Settings/Settings'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Home = React.lazy(() => import('./views/pages/home/Home'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

export default function App() {
  // const { user, isLoading, isAuthenticated } = useAuth0()
  let auth0 = useAuth0()
  const { user, isLoading, isAuthenticated } = fakeAuth() || auth0

  return (
    <TimezoneProvider>
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route
              exact
              path="*"
              name="Home Page"
              element={isAuthenticated ? <DefaultLayout /> : <Home />}
            />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </TimezoneProvider>
  )
}
